*,
*::after,
*::before {
	padding: 0;
	margin: 0;
	box-sizing: inherit;
}

body {
	box-sizing: border-box;
	font-family: Poppins;
	font-size: 12px;
	text-size-adjust: 100%;
}

:root {
	--font-size: 12px;
}

.ptro-holder-wrapper {
	z-index: 2000;
}

@media print {
	body {
		min-width: 460px;
	}
}
